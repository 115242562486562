import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import logoTop from "./assets/images/credit-score-logo.svg";
import { useAuth } from "./hooks/AuthProvider";
import { screenshot } from "./lib/dom";

export default function App() {
  const [checkoutId, setCheckoutId] = useState("");
  const CTA = useRef(null);
  const user = useAuth();

  useEffect(() => {
    if (typeof document !== "undefined") {
      console.log("getting CTA...");
      const checkForButton = () => {
        const button = document.querySelector(".wpwl-button.wpwl-button-pay");
        if (button) {
          console.log("CTA has been found");
          button.addEventListener("click", handleScreenshot);
          clearInterval(intervalId);
        }
      };

      const intervalId = setInterval(checkForButton, 1000);

      return () => {
        clearInterval(intervalId);
        const button = document.querySelector(".wpwl-button.wpwl-button-pay");
        if (button) {
          button.removeEventListener("click", handleScreenshot);
        }
      };
    }
  }, []);

  useEffect(() => {
    console.log("REACT_APP_API_URL", process.env.REACT_APP_API_URL);

    const id = new URLSearchParams(window.location.search).get("id");
    if (id) {
      fetch(`${process.env.REACT_APP_API_URL}/api/total/checkouts/?id=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("data : ", data);
          window.location.href = process.env.REACT_APP_SUCCESS_URL;
        })
        .catch((error) => {
          console.log("error : ", error);
          window.location.href = process.env.REACT_APP_FAIL_URL;
        });
      return;
    }
    if (user?.token) {
      fetch(`${process.env.REACT_APP_API_URL}/api/total/checkouts/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setCheckoutId(data?.id);
          const script = document.createElement("script");
          const script2 = document.createElement("script");
          script.src = `${process.env.REACT_APP_TOTAL_URL}/v1/paymentWidgets.js?checkoutId=${data?.id}/registration`;
          script2.innerHTML = `
          var wpwlOptions = {
            onReady: function() {
              var createTitle = '<h3 class="tl-title">Payment Detail</h3>';
              var createRegistrationHtml = '<div class="note">By providing your card information, you allow Credit-Score to charge your card for future payments in accordance with their terms.</div>';
              $('form.wpwl-form-card').find('.wpwl-group.wpwl-group-brand').before(createTitle);
              $('form.wpwl-form-card').find('.wpwl-button').before(createRegistrationHtml);
              $('.wpwl-label-brand').html('Card Brand');
            },
            iframeStyles: {
              'card-number-placeholder': {
                'background-color': 'transparent',
              },
            }
          }
        `;
          script.async = true;
          script2.async = true;
          document.body.appendChild(script);
          document.body.appendChild(script2);
        })
        .catch((error) => {
          console.log("error : ", error);
          window.location.href = process.env.REACT_APP_FAIL_URL;
        });
    }
  }, [user]);

  const handleScreenshot = async () => {
    const appElement = document.querySelector(".App");
    if (!appElement) {
      console.error(".App element not found");
      return;
    }

    if (!user?.token) {
      console.error("User token not available");
      return;
    }

    try {
      await screenshot(appElement, user.token);
      console.error("Success send user media:");
    } catch (error) {
      console.error("Failed send user media:", error);
    }
  };

  console.log({ checkoutId, user });
  return (
    <div className="App payment-page">
      <div className="logo">
        <img src={logoTop} alt="Credit-Score" />
      </div>
      {checkoutId && (
        <form
          // onSubmit={handleScreenshot}
          action="?success=true"
          className="paymentWidgets"
          data-brands="VISA MASTER AMEX APPLEPAY GOOGLEPAY"
        ></form>
      )}
    </div>
  );
}
