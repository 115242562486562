import html2canvas from "html2canvas";

/**
 * Captures a screenshot of the given element and uploads it to the server.
 *
 * @param {HTMLElement} element - The DOM element to be screenshotted.
 * @param {string} token - Authentication token for uploading the image to the server.
 * @returns {Promise<string>} A Promise that resolves with the base64 string of the screenshot image.
 * @throws {Error} If there's an error capturing the screenshot or uploading the image.
 */

export async function screenshot(element, token) {
  const canvas = await html2canvas(element, {
    useCORS: true, // prevent from blocked by browser CORS policy
  });

  if (canvas) {
    const base64Image = canvas.toDataURL("image/png");

    await fetch(`${process.env.REACT_APP_API_URL}/api/upload-image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ media: base64Image }),
    });
    return Promise.resolve(base64Image);
  }
}
